const mobileMenuButton = document.getElementById('mobile-menu-button');
const mobileMenu = document.querySelector('.mobile-menu');
const about = document.getElementById('about-menu-button');
const aboutMenu = document.getElementById('about-menu');
const services = document.getElementById('services-menu-button');
const servicesMenu = document.getElementById('services-menu');

mobileMenuButton.addEventListener('click', () => {
  mobileMenu.classList.toggle('-translate-x-full');
});

about.addEventListener('click', () => {
  aboutMenu.classList.toggle('hidden');
});

services.addEventListener('click', () => {
  servicesMenu.classList.toggle('hidden')
})

window.addEventListener('click', (e) => {
  if (!isMobileMenuClick(e) && !mobileMenu.classList.contains('-translate-x-full')) {
    mobileMenu.classList.toggle('-translate-x-full');
  }
  
  if (e.target.id !=='about-menu-button' && !aboutMenu.classList.contains('hidden')) {
    aboutMenu.classList.toggle('hidden');
  }
  
  if (e.target.id !=='services-menu-button' && !servicesMenu.classList.contains('hidden')) {
    servicesMenu.classList.toggle('hidden');
  }
  

  
});

const isMobileMenuClick = function (e) {
  return e.target.parentNode.id  === 'mobile-menu-button' || e.target.parentNode.parentNode.id === 'mobile-menu-button'
}
